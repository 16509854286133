export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37')
];

export const server_loads = [];

export const dictionary = {
		"/": [3],
		"/bonus": [4],
		"/casino/demo/[gameID]": [5],
		"/casino/live": [6],
		"/casino/play/[gameID]": [7],
		"/casino/slots": [8],
		"/crash": [9],
		"/creturn/[location]": [10],
		"/dice": [11],
		"/double": [12],
		"/draws": [13],
		"/draws/[drawID]": [14],
		"/enter": [15],
		"/fairness": [16],
		"/jackpot": [17],
		"/kyc-policy": [18],
		"/mines": [19],
		"/overgo": [20],
		"/plinko": [21],
		"/pre-auth": [22],
		"/privacy-policy": [23],
		"/profile": [24],
		"/raffle": [26],
		"/responsible-gaming": [27],
		"/r/[refID]": [25],
		"/slot": [28],
		"/terms-of-use": [29],
		"/tlg/x8do3jykbkwaddte7dyiwddr": [30],
		"/tournament": [31],
		"/tower": [32],
		"/wallet/deposit": [33,[2]],
		"/wallet/disputes": [34,[2]],
		"/wallet/history": [35,[2]],
		"/wallet/withdraw": [36,[2]],
		"/wheel": [37]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';